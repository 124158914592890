<template>
  <div >
    <div class="">
        <div class="row justify-content-center">
            <div
                v-for="(discussion,index) in discussions"
                    :key="index++"
                    class="col-xl-7 col-12 my-2"
                >
                <div class="w-75 row" 
                    :class="discussion.isOwner==true?' ms-auto me-0 text-end':''">
                    <div v-if="discussion.isOwner==false " class="fw-bold p-2 mx-2 col-2">
                        {{ discussion.fieldFirstName }}
                    </div>
                    <div class="p-2 rounded col-auto"
                        :class="discussion.isOwner == true
                        ? ' ms-auto me-0 bg-primary text-white text-end'
                        : 'bg-gray '">
                    
                        {{ discussion.content }}

                        <span>
                          <button class="btn btn-danger">
                            <i class="bi bi-trash"></i>
                          </button>
                        </span>
                    </div>
                    
                    <!-- <span v-if="discussion" class="m-auto">
                        {{ discussion.created_at }}
                    </span> -->
                </div>
            </div>
            <div class="col-xl-7 col-12 mt-2">
                <form @submit.prevent="submitDiscussion"
                class="form-control border rounded p-0 m-0 w-100 row d-flex ">
                    
                    <input type="text" placeholder="Write a message" v-model="content"
                        class="col p-2 border-0 outline-none ">
                    <button type="submit" class="bg-none border-0 col-auto">
                        <i class="bi bi-send-fill"></i>
                    </button>
                </form>
            </div>
        </div>
    </div>
    <br>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
name: 'Discussion',
  middleware: 'auth',
  components:{},
  data() {
    return {
        content:''
    }
  },
  beforeCreate() {
    this.$store.dispatch("discussion/fetch");
  },
  computed: {
    ...mapGetters("discussion", { discussions: "getDiscussions" }),
    imageUrl() {
      return (item) => `${item.logo}`
    },
  },
  methods:{
    async submitDiscussion() {
        await this.$store.dispatch("discussion/store", {
              content: this.content,
        });
          await (this.content='');
    }
  }
}
</script> 

<style scoped>
.sponsorship-card {
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}
</style>